import { AnimatePresence } from "framer-motion";
import React from "react";
import "animate.css";

export default function PageWrapper({ children, ...rest }) {
  return (
    <div id="PageWrapper" {...rest}>
      <>
        <title>Holger Rune</title>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/default-favicon.ico"
        />
        <meta property="og:site_name" content="Holger Rune" />
        <meta property="og:title" content="Holger Rune" />
        <meta property="og:url" content="https://www.HolgerRune.com" />
        <meta property="og:type" content="website" />
        <meta itemprop="name" content="Holger Rune" />
        <meta itemprop="url" content="https://www.HolgerRune.com" />
        <meta name="twitter:title" content="Holger Rune" />
        <meta name="twitter:url" content="https://www.HolgerRune.com" />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content="Holger Vitus Nødskov Rune is a Danish professional tennis player. Holger reach the ATP top 10 in singles on 7 November 2022 after a great run - Winning ATP Munich 250, ATP Stockholm 250 and finally Paris ATP 1000" />
      </>
      <AnimatePresence exitBeforeEnter initial={false}>
        {children}
      </AnimatePresence>
    </div>
  );
}
