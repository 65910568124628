import { useState, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";
export default function useMediaQuery() {
  const [isDesktop, setIsDesktop] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const ro = new ResizeObserver((e, observer) => {
        if (window.innerWidth > 767) {
          setIsDesktop(true);
        } else if (window.innerWidth < 768) {
          setIsDesktop(false);
        }
      });
      ro.observe(document.body);
      return () => {
        ro.unobserve(document.body);
      };
    }
  }, []);

  return isDesktop;
}
