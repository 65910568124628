import React from "react";
import { css } from "@emotion/react";
import cn from "classnames";
export default function Circle({
  independent,
  className = "",
  scale = 1,
  r = 24,
  stroke,
  isHovered,
}) {
  return (
    <svg
      width={30}
      height={45}
      className={cn([
        className,
        {
          group: independent,
        },
      ])}
      css={css`
        transform: scale(${scale});
      `}
      viewBox={`0 0 ${22} ${45}`}
    >
      <rect
        strokeWidth={1}
        fill="transparent"
        width="30"
        height="45"
        stroke={stroke}
        style={{ transform: "matrix(0.79744, 0, 0, 0.79885, 0.268, 4.1999)" }}
      />

      <line
        strokeDashoffset={30}
        strokeDasharray={`${30} ${30}`}
        className={`transform ${
          isHovered ? "stroke-offset-0" : ""
        } duration-300`}
        x1="0"
        x2="24"
        y1="11.25"
        y2="11.25"
        stroke={stroke}
        strokeWidth={0.5}
      />
      <line
        strokeDashoffset={-30}
        strokeDasharray={`${30} ${30}`}
        className={`transform ${
          isHovered ? "stroke-offset-0" : ""
        }  duration-300`}
        x1="0"
        x2="24"
        y1="22.50"
        y2="22.50"
        stroke={stroke}
        strokeWidth={0.5}
      />
      <line
        strokeDashoffset={30}
        strokeDasharray={`${30} ${30}`}
        className={`transform ${
          isHovered ? "stroke-offset-0" : ""
        } duration-300`}
        x1="0"
        x2="24"
        y1="33.75"
        y2="33.75"
        stroke={stroke}
        strokeWidth={0.5}
        autoReverse={true}
      />
      <line
        strokeDashoffset={-11.25}
        strokeDasharray={`${11.25} ${11.25}`}
        className={`transform ${
          isHovered ? "stroke-offset-0" : ""
        } duration-200`}
        x1="12"
        x2="12"
        y1="22.50"
        y2="11.75"
        stroke={stroke}
        strokeWidth={0.7}
      />
      <line
        strokeDashoffset={11.25}
        strokeDasharray={`${11.25} ${11.25}`}
        className={`transform ${
          isHovered ? "stroke-offset-0" : ""
        } duration-200`}
        x1="12"
        x2="12"
        y1="22.50"
        y2="33.75"
        stroke={stroke}
        strokeWidth={0.7}
      />
    </svg>
  );
}
