import React from "react"
import { LazyMotion, domAnimation } from "framer-motion"
import Menu from "./src/components/Menu"
import PageWrapper from "./src/components/PageWrapper"
import store from "./src/components/Store"
import smoothscroll from "smoothscroll-polyfill"
import "fullscreen-polyfill"
import "./src/styles/global.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import prom from "es6-promise"

prom.polyfill()
smoothscroll.polyfill()

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

export const onRouteUpdate = () => {
  const { getState } = store
  const { setMenuIsOpen } = getState()
  setMenuIsOpen(false)
}

export function wrapPageElement({ element, ...props }) {
  return (
    <LazyMotion features={domAnimation}>
      <Menu />
      <PageWrapper {...props.props}>{element}</PageWrapper>
    </LazyMotion>
  )
}

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const animationDuraion = 0.3
  const scrollDelay = animationDuraion * 1000

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), scrollDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      scrollDelay
    )
  }

  // https://github.com/gatsbyjs/gatsby/issues/28794/
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual"
  }

  return false
}
