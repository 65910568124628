import { motion } from "framer-motion"
import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import { css } from "@emotion/react"
import Circle from "../Circle"
import { Link } from "gatsby"
import Navigation from "./navigation"
import useStore from "../Store"
import cn from "classnames"

export default function Menu() {
  const menuIsOpen = useStore((state) => state.menuIsOpen)
  const setMenuIsOpen = useStore((state) => state.setMenuIsOpen)

  return (
    <div className="">
      {" "}
      <div
        id="Menu"
        className="px-4 md:px-8 fixed-top top-1/2 z-50 h-[63px] sm:h-[70px] xl:h-[90px]"
        css={css`
          transform: translate3d(0, 0, 0);
        `}
      >
        <div className="flex justify-between items-center h-full ">
          <MenuInfo
            isOpen={menuIsOpen}
            toggle={() => setMenuIsOpen(!menuIsOpen)}
          />
          <div></div>
          <MenuButton
            isOpen={menuIsOpen}
            toggle={() => setMenuIsOpen(!menuIsOpen)}
          />
        </div>
      </div>
      <div className="absolute left-1/2 -translate-x-1/2 z-10 mx-auto w-fit">
        {/* Desktop */}
        <div className="hidden xl:block">
          <iframe
            src="https://static.rolex.com/clocks/2022/Holger_Rune_Desk_HTML_200x90/rolex.html"
            style={{
              width: "200px",
              height: "90px",
              border: 0,
              margin: 0,
              padding: 0,
              overflow: "hidden",
              scroll: "none",
            }}
            SCROLLING="NO"
            loading="lazy"
            frameborder="NO"
          ></iframe>
        </div>
        {/* Tablet */}
        <div className="hidden sm:block xl:hidden">
          <iframe
            src="https://static.rolex.com/clocks/2022/Holger_Rune_Tablet_HTML_160x70/rolex.html"
            style={{
              width: "160px",
              height: "70px",
              border: 0,
              margin: 0,
              padding: 0,
              overflow: "hidden",
              scroll: "none",
            }}
            SCROLLING="NO"
            loading="lazy"
            frameborder="NO"
          ></iframe>
        </div>
        {/* Mobile */}
        <div className="block sm:hidden">
          <iframe
            src="https://static.rolex.com/clocks/2022/Holger_Rune_mobil_HTML_144x63/rolex.html"
            style={{
              width: "144px",
              height: "63px",
              border: 0,
              margin: 0,
              padding: 0,
              overflow: "hidden",
              scroll: "none",
            }}
            SCROLLING="NO"
            loading="lazy"
            frameborder="NO"
          ></iframe>
        </div>
      </div>
      <Navigation
        isOpen={menuIsOpen}
        toggle={() => setMenuIsOpen(!menuIsOpen)}
      />
    </div>
  )
}

const MenuInfo = ({ isOpen }) => {
  return (
    <div className="flex items-center flex-grow">
      {" "}
      <motion.div
        initial={{ opacity: 0, scaleX: 0 }}
        exit={{ opacity: 0, scaleX: 0 }}
        animate={{
          opacity: 1,
          scaleX: 1,
          transition: {
            duration: 0.7,
            ease: "easeOut",
          },
        }}
        className={cn("h-px w-5 origin-left mr-3 bg-white")}
      ></motion.div>{" "}
      <motion.div
        initial={{ opacity: 0, y: 5 }}
        exit={{ opacity: 0, y: -5 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { delay: 0.3, duration: 0.7, ease: "easeOut" },
        }}
        className={cn("text-xs")}
      >
        <Link to="/">HOME</Link>
      </motion.div>
    </div>
  )
}

function MenuButton({ isOpen, toggle }) {
  const [hover, setHover] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const posBottom = {
    y: "110%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }

  const active = {
    y: 0,
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }
  const posTop = {
    y: "-110%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }

  const MenuV = {
    false: {
      false: active,
      true: posTop,
    },
    true: {
      false: posTop,
      true: posTop,
    },
  }
  const OpenV = {
    false: {
      false: posBottom,
      true: active,
    },
    true: {
      false: posTop,
      true: posTop,
    },
  }
  const CloseV = {
    false: {
      false: posBottom,
      true: posBottom,
    },
    true: {
      false: active,
      true: active,
    },
  }

  useEffect(() => {
    if (!isOpen) {
      setIsHovered(false)
    }
  }, [isOpen])

  return (
    <div className="flex-grow flex justify-end">
      {" "}
      <motion.button
        className="focus:outline-none group  flex items-center"
        onClick={toggle}
        onHoverStart={() => {
          setHover(true)
          setIsHovered(true)
        }}
        onHoverEnd={() => {
          setHover(false)
          setIsHovered(false)
        }}
      >
        <div
          className={cn("hidden md:block text-xs")}
          css={css`
            position: relative;
            overflow: hidden;
            height: 1rem;
            flex-grow: 1;
            width: 150px;
            /* @lg */
            @media (min-width: 1024px) {
              width: 4vw;
            }

            span {
              ${tw`block  absolute uppercase top-0 right-0 `}
              white-space: nowrap;
              transition-property: transform, opacity;
              transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
              transition-duration: 0.4s;
            }
          `}
        >
          <motion.span initial={active} animate={MenuV[isOpen][hover]}>
            Menu
          </motion.span>
          <motion.span initial={posBottom} animate={OpenV[isOpen][hover]}>
            Open
          </motion.span>

          <motion.span initial={posBottom} animate={CloseV[isOpen][hover]}>
            Close
          </motion.span>
        </div>
        <div className="ml-3 relative">
          {/* <Dots isOpen={isOpen} /> */}
          <motion.div
            onHoverStart={() => {
              setIsHovered(true)
            }}
            onClick={() => {
              if (window.document.body.clientWidth < 1024) {
                setIsHovered(!isHovered)
              }
            }}
            onHoverEnd={() => {
              setIsHovered(false)
            }}
          >
            <Circle isHovered={isHovered} stroke={"#fff"} />
          </motion.div>
          {/* <div id="lottie"></div> */}
        </div>
      </motion.button>
    </div>
  )
}
